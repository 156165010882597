import { Grid } from '@material-ui/core';
import React from 'react';
import { EloActivityType } from '../../data/elo-activities';
import { useGtXs } from '../../hooks/useGtXs';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import styles from './EloActivity.module.scss';

interface EloActivityProps {
    activity: EloActivityType
}

export default function EloActivity(props: EloActivityProps) {


    const { activity } = props

    const gtXs = useGtXs()

    return (
        <Grid container className={styles.Activity}>

            <Grid container id={styles.FirstRow}>

                <Grid item xs={12} sm={4}>
                    <Typography variant="RocGroteskBold30" paragraph className={styles.Title}>
                        {activity.title}
                    </Typography>
                    <Typography variant="RocGroteskRegular30" paragraph className={styles.Subtitle}>
                        {activity.subtitle}
                    </Typography>
                </Grid>

                <Show on={gtXs}>
                    <Grid container item xs={12} sm={8} alignContent="flex-end" className={styles.Classes}>
                        <div>
                            {activity?.classes?.map((cls, index) =>
                                <Typography variant="RocGroteskRegular18" key={index} component="p">
                                    {cls}
                                </Typography>
                            )}
                        </div>
                    </Grid>
                </Show>

            </Grid>

            <Grid container id={styles.SecondRow}>
                <Grid item xs={12} sm={4} className={styles.Banner}>
                    <img src={activity.banner} alt="" />
                </Grid>

                <Show on={!gtXs}>
                    <Grid container item xs={12} sm={8} alignContent="flex-end" className={styles.Classes}>
                        <div>
                            {activity?.classes?.map((cls, index) =>
                                <Typography variant="RocGroteskRegular18" key={index} component="p">
                                    {cls}
                                </Typography>
                            )}
                        </div>
                    </Grid>
                </Show>

                <Grid item xs={12} sm={8} className={styles.Description}>
                    <Typography variant="RocGroteskMedium20">
                        {activity.description}
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
    )
}
