import { Grid } from '@material-ui/core'
import React from 'react'
import facebook from '../../assets/images/footer/facebook.webp'
import logos from '../../assets/images/footer/footer_logos.webp'
import instagram from '../../assets/images/footer/instagram.webp'
import twitter from '../../assets/images/footer/twitter.webp'
import youtube from '../../assets/images/footer/youtube.webp'
import { useGtXs } from '../../hooks/useGtXs'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import styles from './Supporters.module.scss'

export const socialNetworks = [
    { id: 1, src: instagram, route: "https://www.instagram.com/coliseuportoageas/", alt: "instagram coliseu" },
    { id: 2, src: facebook, route: "https://www.facebook.com/ColiseuPortoAgeas/", alt: "facebook coliseu" },
    { id: 3, src: youtube, route: "https://www.youtube.com/@coliseuportoageas4060", alt: "youtube coliseu" },
    { id: 4, src: twitter, route: "https://twitter.com/ColiseuPorto", alt: "twitter coliseu" },
]


export default function Supporters() {

    const gtXs = useGtXs()
    const align = gtXs ? "flex-end" : "center"

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="section" justify="space-between" alignItems="center">
                <Grid item xs={12} md={10} lg={8} className={styles.SupportersLogos}>
                    <img src={logos} alt="parceiros e apoio à divulgação" />
                </Grid>
                <Grid item container xs={12} md={2} lg={4} justify={align} className={styles.SocialNetworks}>
                    {socialNetworks.map(network => (
                        <Link key={network.id} route={network.route}>
                            <img src={network.src} alt={network.alt} />
                        </Link>
                    ))}
                </Grid>
                <Grid container className={styles.Policies}>
                    <Typography variant="caption">
                        <Link route="/politica-de-privacidade" name="Política de Privacidade" />
                    </Typography>
                    <Typography variant="caption">
                        <Link route="/cookies" name="Política de Utilização de Cookies" />
                    </Typography>
                    <Typography variant="caption">
                        <Link route="/direito-a-imagem" name="Direitos de Imagem" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
