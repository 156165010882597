import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import elo_hero_desktop from '../../assets/images/educational/elo/elo_hero_desktop.webp';
import elo_hero_mobile from '../../assets/images/educational/elo/elo_hero_mobile.webp';
import eloLogo from '../../assets/images/educational/elo/elo_logo.svg';
import elo_promoters_logos_desktop from '../../assets/images/educational/elo/elo_promoters_logos_desktop.webp';
import elo_promoters_logos_mobile from '../../assets/images/educational/elo/elo_promoters_logos_mobile.webp';
import eloActivities from '../../data/elo-activities';
import eloGalleryPhotos from '../../data/elo-gallery-photos';
import { useGtXs } from '../../hooks/useGtXs';
import getSlides from '../../utils/getSlides';
import EloActivity from '../EloActivity/EloActivity';
import Hero from '../Hero/Hero';
import OutlineButton from '../OutlineButton/OutlineButton';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import VideoPlayerAspect from '../VideoPlayerAspect/VideoPlayerAspect';
import styles from './EloView.module.scss';

export default function EloView() {

    const gtXs = useGtXs()
    const history = useHistory()

    const heroImages = {
        xs: elo_hero_mobile,
        gtXs: elo_hero_desktop
    }

    const videoUrl = "https://www.youtube.com/watch?v=L-29VELfkJE"
    const newsUrl = "/noticia/apos-nove-meses-de-trabalho-projeto-elo-termina-em-apoteose-no-coliseu"
    const [isOpen, setIsOpen] = useState<boolean>(false)


    const startIndex = 0
    const slides = getSlides(eloGalleryPhotos)

    return (
        <div className={styles.Wrapper}>
            <Hero images={heroImages} />
            <Grid container justify="center">
                <main>

                    <Grid container id={styles.Introduction}>
                        <Grid item xs={12} sm={4}>
                            <div className={styles.Logo}>
                                <img src={eloLogo} alt="logo do projeto elo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={8} className={styles.Blurb}>
                            <Typography variant="RocGroteskRegular30" paragraph>
                                2024 <b>NOVEMBRO</b> <Show on={gtXs}>-</Show> 2025 <b>MAIO</b>
                            </Typography>
                            <Typography variant="RocGroteskMedium25">
                                O Coliseu Porto Ageas, a Irmandade dos Clérigos e a Associação Comercial do Porto formam
                                o novo “ELO”, um projeto cultural, social e inclusivo. Entre novembro de 2024 e maio de 2025,
                                três das mais importantes instituições portuenses pretendem juntar no Coliseu participantes
                                das muitas nacionalidades que habitam hoje o Porto, em aulas de música, dança inclusiva e teatro.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container id={styles.Presentation}>
                        <Typography variant="RocGroteskRegular25">
                            O projeto pretende simbolizar a ligação entre diferentes disciplinas artísticas (música, teatro,
                            dança e storytelling), setores (social e educativo) e pessoas de diferentes idades e contextos
                            sociais (público em geral, de todas as nacionalidades, com e sem deficiência), bem como alunos,
                            auxiliares e pais de uma escola do Porto, local onde hoje convivem, mais do que nunca,
                            diferentes idiomas e culturas que escolhem o Porto para viver.
                        </Typography>
                    </Grid>


                    <Grid container id={styles.Activities} component="section">
                        {eloActivities?.map(activity =>
                            <EloActivity activity={activity} key={activity.id} />
                        )}
                    </Grid>

                    <Grid container id={styles.LastEdition} component="section">
                        <Grid container>

                            <Grid item xs={12} sm={4} id={styles.Report}>
                                <Show on={gtXs}>
                                    <Typography variant="RocGroteskBold30" paragraph>
                                        ELO
                                    </Typography>
                                    <Typography variant="RocGroteskRegular25" paragraph>
                                        1ª EDIÇÃO (2023 - 2024)
                                    </Typography>
                                </Show>
                                <Typography variant="RocGroteskRegular16" paragraph>
                                    Em outubro de 2023, o Coliseu Porto Ageas e a Irmandade dos Clérigos uniram-se para a criação do ELO.
                                </Typography>
                                <Typography variant="RocGroteskRegular16" paragraph>
                                    Quantas memórias se formaram no Coliseu e nos Clérigos ao longo dos tempos? Festas, comícios,
                                    paixões e até um incêndio são fragmentos que formam o Património Imaterial destas duas Torres
                                    da cidade do Porto. Com o Projeto ELO, o Serviço Educativo Coliseu partiu em busca das muitas
                                    histórias que os utentes de diversas instituições sociais e universidades seniores do Porto
                                    guardam em si. Mostrámos a mais de 200 pessoas com mais de 65 anos os segredos do Coliseu e
                                    dos Clérigos, e delas recebemos a doação das suas histórias.
                                </Typography>
                                <Typography variant="RocGroteskRegular16" paragraph>
                                    Foi a partir delas que se desenvolveu o espetáculo final do ELO, que, em junho de 2024, subiu
                                    ao grande palco do Coliseu, onde a dança, o teatro e a música se encontraram, assim como todos os participantes
                                    deste projeto único em Portugal - incluindo, pela primeira vez em todo o projeto, os reclusos do
                                    Estabelecimento Prisional do Porto, em Custóias.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} id={styles.Video}>
                                <Show on={!gtXs}>
                                    <Typography variant="RocGroteskBold30" paragraph>
                                        ELO
                                    </Typography>
                                    <Typography variant="RocGroteskRegular25" paragraph>
                                        1ª EDIÇÃO (2023 - 2024)
                                    </Typography>
                                </Show>
                                <div>
                                    <VideoPlayerAspect url={videoUrl} />
                                </div>
                                <Grid container spacing={gtXs ? 4 : 1} className={styles.Actions}>
                                    <Grid item xs={12} sm={6}>
                                        <OutlineButton
                                            name="Saber Mais"
                                            onClick={() => history.push(newsUrl)}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutlineButton
                                            name="Galeria"
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                    <Grid container id={styles.Promoters} justify="center" component="section">
                        <img src={gtXs ? elo_promoters_logos_desktop : elo_promoters_logos_mobile} alt="Promotores" />
                    </Grid>

                </main>
            </Grid>
            <Lightbox
                open={isOpen}
                close={() => setIsOpen(false)}
                index={startIndex}
                slides={slides}
                carousel={{
                    finite: true
                }}
                className={styles.LightBox}
            />
        </div>
    )
}
