import React, { ReactNode } from "react"
import ArchiveView from "../ArchiveView/ArchiveView"
import AwardView from "../AwardView/AwardView"
import BalleteatroView from "../BalleteatroView/BalleteatroView"
import BookingInformationView from "../BookingInformationView/BookingInformationView"
import BuildingView from "../BuildingView/BuildingView"
import CircusView from "../CircusView/CircusView"
import CollectionView from "../CollectionView/CollectionView"
import ContactsView from "../ContactsView/ContactsView"
import CookiesView from "../CookiesView/CookiesView"
import DirectionsView from "../DirectionsView/DirectionsView"
import EducationalInformationView from "../EducationalInformationView/EducationalInformationView"
import EducationalSectionView from "../EducationalSectionView/EducationalSectionView"
import EloView from "../EloView/EloView"
import FriendsView from "../FriendsView/FriendsView"
import HistoryView from "../HistoryView/HistoryView"
import ImageRightsView from "../ImageRightsView/ImageRightsView"
import MantrasView from "../MantrasView/MantrasView"
import NewsArchiveView from "../NewsArchiveView/NewsArchiveView"
import PaintingStudioView from "../PaintingStudioView/PaintingStudioView"
import PostDetailsView from "../PostDetailsView/PostDetailsView"
import PresentationView from "../PresentationView/PresentationView"
import PrivacyView from "../PrivacyView/PrivacyView"
import ReportsView from "../ReportsView/ReportsView"
import RoomNormsView from "../RoomNormsView/RoomNormsView"
import ScheduleViewGrid from "../ScheduleViewGrid/ScheduleViewGrid"
import SchoolsView from "../SchoolsView/SchoolsView"
import SocialEntitiesView from "../SocialEntitiesView/SocialEntitiesView"
import SpacesView from "../SpacesView/SpacesView"
import StatutesView from "../StatutesView/StatutesView"
import SubscribeView from "../SubscribeView/SubscribeView"
import TechnicalAreaView from "../TechnicalAreaView/TechnicalAreaView"
import ToursView from "../ToursView/ToursView"
import ActivistTheaterClubView from "../ActivistTheaterClubView/ActivistTheaterClubView"

export interface SubSection {
    id: number
    name: string
    route: string
    component?: ReactNode
    hide?: boolean
}

export interface Section {
    id: number
    name: string
    route: string
    hide?: boolean
    subsections?: SubSection[]
}

export const sections: Section[] = [
    {
        id: 1,
        name: "Agenda",
        route: "/agenda",
        subsections: [
            {
                id: 11,
                name: "Agenda",
                route: "/agenda",
                component: <ScheduleViewGrid />
            },
            {
                id: 12,
                name: "Arquivo",
                route: "/arquivo",
                component: <ArchiveView />
            }
        ]
    },
    {
        id: 2,
        name: 'O Coliseu',
        route: '/espacos',
        subsections: [
            {
                id: 21,
                route: '/espacos',
                name: 'Espaços Coliseu',
                component: <SpacesView />
            },
            {
                id: 22,
                route: '/apresentacao',
                name: 'Apresentação',
                component: <PresentationView />
            },
            {
                id: 23,
                route: '/historia',
                name: 'História',
                component: <HistoryView />
            },
            {
                id: 24,
                route: '/edificio',
                name: 'Edifício e Arquitetura',
                component: <BuildingView />
            },
            {
                id: 25,
                route: '/visitas',
                name: 'Visitas Guiadas',
                component: <ToursView />
            }
        ]
    },
    {
        id: 3,
        name: 'Serviço Educativo',
        route: '/educativo',
        subsections: [
            {
                id: 31,
                name: 'Serviço Educativo',
                route: '/educativo',
                component: <EducationalSectionView />
            },
            {
                id: 32,
                name: 'Programa escolas',
                route: '/educativo/programa-escolas',
                component: <SchoolsView />
            },
            {
                id: 33,
                name: 'ELO',
                route: '/educativo/elo',
                component: <EloView />

            },
            {
                id: 34,
                name: 'CTA',
                route: '/educativo/cta',
                component: <ActivistTheaterClubView />
            },
            {
                id: 35,
                name: 'Ateliers',
                route: '/educativo/atelier-pintura',
                component: <PaintingStudioView />
            },
            {
                id: 36,
                name: 'Informações',
                route: '/educativo/informacoes',
                component: <EducationalInformationView />
            }
        ]
    },
    {
        id: 4,
        name: 'Programas Coliseu',
        route: '/mantras',
        subsections: [
            {
                id: 41,
                name: 'Mantras Coliseu',
                route: '/mantras',
                component: <MantrasView />
            },
            {
                id: 42,
                route: '/circo',
                name: 'Circo',
                component: <CircusView />
            },
            {
                id: 43,
                route: '/premio',
                name: 'Prémio Jovens Artistas',
                component: <AwardView />
            },
            {
                id: 44,
                route: '/collection',
                name: 'Coliseu Collection',
                component: <CollectionView />
            }
        ]
    },
    {
        id: 5,
        name: "Notícias",
        route: '/arquivo-noticias',
        subsections: [
            {
                id: 51,
                route: '/arquivo-noticias',
                name: 'Notícias',
                component: <NewsArchiveView />,
            },
            {
                id: 52,
                name: "Newsletter",
                route: "/newsletter/subscrever",
                component: <SubscribeView />
            },
            {
                id: 53,
                route: '/noticia/:slug',
                name: 'Notícia',
                component: <PostDetailsView />,
                hide: true
            }
        ]
    },
    {
        id: 6,
        name: "Institucional",
        route: '/amigos',
        subsections: [
            {
                id: 61,
                route: '/amigos',
                name: 'Amigos Coliseu',
                component: <FriendsView />
            },
            {
                id: 62,
                route: '/orgaos-sociais',
                name: 'Órgãos Sociais',
                component: <SocialEntitiesView />,
            },
            {
                id: 63,
                route: '/estatutos',
                name: 'Estatutos',
                component: <StatutesView />,
            },
            {
                id: 64,
                route: '/relatorios',
                name: 'Relatórios',
                component: <ReportsView />,
            },
            {
                id: 65,
                route: '/balleteatro',
                name: 'Balleteatro',
                component: <BalleteatroView />,
            }
        ]
    },
    {
        id: 7,
        name: "Informações",
        route: '/bilheteira',
        subsections: [
            {
                id: 71,
                name: "Bilheteira",
                route: "/bilheteira",
                component: <BookingInformationView />
            },
            {
                id: 72,
                route: '/contactos',
                name: 'Contactos',
                component: <ContactsView />
            },
            {
                id: 73,
                name: "Como Chegar",
                route: "/como-chegar",
                component: <DirectionsView />
            },

            {
                id: 74,
                route: '/normas-sala',
                name: 'Normas da Sala',
                component: <RoomNormsView />
            },
            {
                id: 75,
                route: '/area-tecnica',
                name: 'Área Técnica',
                component: <TechnicalAreaView />
            }
        ]
    },
    {
        id: 8,
        name: 'Politicas',
        route: '/politica-de-privacidade',
        hide: true,
        subsections: [
            {
                id: 81,
                route: '/politica-de-privacidade',
                name: 'Política Privacidade',
                component: <PrivacyView />
            },
            {
                id: 82,
                route: '/cookies',
                name: 'Política Cookies',
                component: <CookiesView />
            },
            {
                id: 83,
                route: '/direito-a-imagem',
                name: 'Direito Imagem',
                component: <ImageRightsView />
            }
        ]
    }
]
