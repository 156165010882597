import danceBanner from '../assets/images/educational/elo/dance_banner.webp';
import godmotherBanner from '../assets/images/educational/elo/godmother_banner.webp';
import musicBanner from '../assets/images/educational/elo/music_banner.webp';
import theaterBanner from '../assets/images/educational/elo/theater_banner.webp';

export interface EloActivityType {
    id: number
    title: string
    subtitle: string
    banner: any
    classes?: string[]
    description: string
}

const eloActivities: EloActivityType[] = [
    {
        id: 1,
        title: "Madrinha ELO",
        subtitle: "Selma Uamusse",
        banner: godmotherBanner,
        description: "E se em 2023 o nascimento do ELO contou com a participação de Pedro Abrunhosa, a segunda edição será amadrinhada por Selma Uamusse. A cantora, nascida em Moçambique em 1981 e a viver em Portugal desde 1988, transporta na sua música e voz portentosa diferentes influências que já a levaram a brilhar no rock (WrayGunn), afrobeat (Cacique’97), passando pelo gospel, pela soul e pelo jazz (Gospel Collective, tributos a Nina Simone e Miriam Makeba e Rodrigo Leão). Um exemplo da diversidade artística que o \"ELO\" procura representar.",
    },
    {
        id: 2,
        title: "Música",
        subtitle: "Patricia Lestre",
        banner: musicBanner,
        classes: [
            "Música / Coliseu - 46 aulas",
            "Música / Escola - 25 aulas",
        ],
        description: "A música terá uma forte presença, em dois territórios diferentes: no Coliseu, mas bém numa escola do Porto, lugar onde convivem hoje dezenas de nacionalidades e línguas diferentes.Em ambos, os participantes vão ser orientados pela cantora e multi- instrumentista Patrícia Lestre.Os participantes vão aprender a produzir música recorrendo aos seus talentos e vontades, ora através do corpo ora de instrumentos musicais e voz.O grupo escolar será composto por alunos, pais, professores e auxiliares."
    },
    {
        id: 3,
        title: "Dança",
        subtitle: "Eliana Campos",
        banner: danceBanner,
        classes: ["Dança - 46 aulas"],
        description: "A dança inclusiva, orientada pela bailarina e professora de dança Eliana Campos, com o apoio de Beatriz Bizarro, terá em atenção as características e potencialidades de cada participante, com vista à criação de um espetáculo onde todos se poderão transcender."
    },
    {
        id: 4,
        title: "Teatro",
        subtitle: "Pedro Lamares",
        banner: theaterBanner,
        classes: ["Teatro - 46 aulas"],
        description: "O teatro será orientado pelo ator e encenador Pedro Lamares, apoiado por Carolina Rocha e Carlos Correia. Vai formar-se um grupo composto por jovens de instituições sociais e com inscrições abertas para o público em geral, com ou sem experiência em teatro.",
    }
]


export default eloActivities
