import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.scss';
import ActivateView from './components/ActivateView/ActivateView';
import ColisseumView from './components/ColisseumView/ColisseumView';
import CollectionCategoryView from './components/CollectionCategoryView/CollectionCategoryView';
import Consent from './components/Consent/Consent';
import DefaultMetaTags from './components/DefaultMetaTags/DefaultMetaTags';
import DesktopMenu from './components/DesktopMenu/DesktopMenu';
import EducationalActivityEnrollView from './components/EducationalActivityEnrollView/EducationalActivityEnrollView';
import EducationalActivityThanksView from './components/EducationalActivityThanksView/EducationalActivityThanksView';
import EducationalActivityView from './components/EducationalActivityView/EducationalActivityView';
import EducationalCategoryView from './components/EducationalCategoryView/EducationalCategoryView';
import EducationalView from './components/EducationalView/EducationalView';
import EventDetailsView from './components/EventDetailsView/EventDetailsView';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import HomeView from './components/HomeView/HomeView';
import InformationView from './components/InformationView/InformationView';
import InstitutionView from './components/InstitutionView/InstitutionView';
import NewsView from './components/NewsView/NewsView';
import P from './components/PageTransition/PageTransition';
import PolicyView from './components/PolicyView/PolicyView';
import ProgramsView from './components/ProgramsView/ProgramsView';
import ResidencyView from './components/ResidencyView/ResidencyView';
import ScheduleView from './components/ScheduleView/ScheduleView';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SpaceView from './components/SpaceView/SpaceView';
import SubscribeView from './components/SubscribeView/SubscribeView';
import UnsubscribeView from './components/UnsubscribeView/UnsubscribeView';
import client from "./services/Client";
import theme from './theme';

const App = () => {
    console.log(styles)

    const host = window.location.host
    const HomeComponent = !host.startsWith("educativo") ? HomeView : EducationalView

    return (
        <ApolloProvider client={client}>
            <Router>
                <ScrollToTop />
                <DefaultMetaTags />
                <ThemeProvider theme={theme}>
                    <Header />
                    <DesktopMenu />
                    <Consent />
                    <Route exact path="/" render={(p) => <P><HomeComponent {...p} /></P>} />
                    <Route exact path="/evento/:slug" render={(p) => <P><EventDetailsView {...p} /></P>} />
                    <Route exact path="/politica-de-privacidade" render={(p) => <P><PolicyView {...p} /></P>} />
                    <Route exact path="/cookies" render={(p) => <P><PolicyView {...p} /></P>} />
                    <Route exact path="/direito-a-imagem" render={(p) => <P><PolicyView {...p} /></P>} />
                    {/* menu agenda */}
                    <Route exact path="/arquivo" render={(p) => <P><ScheduleView {...p} /></P>} />
                    <Route exact path="/agenda" render={(p) => <P><ScheduleView {...p} /></P>} />
                    {/* menu o-coliseu */}
                    <Route exact path="/espacos" render={(p) => <P><ColisseumView {...p} /></P>} />
                    <Route exact path="/espacos/sala-principal" render={(p) => <P><SpaceView {...p} /></P>} />
                    <Route exact path="/espacos/salao-atico" render={(p) => <P><SpaceView {...p} /></P>} />
                    <Route exact path="/espacos/salao-jardim" render={(p) => <P><SpaceView {...p} /></P>} />
                    <Route exact path="/espacos/lounge-ageas" render={(p) => <P><SpaceView {...p} /></P>} />
                    <Route exact path="/espacos/foyer" render={(p) => <P><SpaceView {...p} /></P>} />
                    <Route exact path="/apresentacao" render={(p) => <P><ColisseumView {...p} /></P>} />
                    <Route exact path="/historia" render={(p) => <P><ColisseumView {...p} /></P>} />
                    <Route exact path="/edificio" render={(p) => <P><ColisseumView {...p} /></P>} />
                    <Route exact path="/visitas" render={(p) => <P><ColisseumView {...p} /></P>} />
                    {/* menu servico educativo */}
                    <Route exact path="/educativo" render={(p) => <P><EducationalView {...p} /></P>} />
                    <Route exact path="/educativo/programa-escolas/atividade/:slug" render={(p) => <P><EducationalActivityView {...p} /></P>} />
                    <Route exact path="/educativo/programa-escolas/inscrever/:slug" render={(p) => <P><EducationalActivityEnrollView {...p} /></P>} />
                    <Route exact path="/educativo/programa-escolas/submissao/:slug" render={(p) => <P><EducationalActivityThanksView {...p} /></P>} />
                    <Route exact path="/educativo/programa-escolas" render={(p) => <P><EducationalView {...p} /></P>} />
                    <Route exact path="/educativo/elo" render={(p) => <P><EducationalView {...p} /></P>} />
                    <Route exact path="/educativo/informacoes" render={(p) => <P><EducationalView {...p} /></P>} />
                    <Route exact path="/educativo/programa-escolas/categoria/:slug" render={(p) => <P><EducationalCategoryView {...p} /></P>} />
                    <Route exact path="/educativo/atelier-pintura" render={(p) => <P><EducationalView {...p} /></P>} />
                    <Route exact path="/educativo/cta" render={(p) => <P><EducationalView {...p} /></P>} />
                    {/* menu programas coliseu */}
                    <Route exact path="/mantras" render={(p) => <P><ProgramsView {...p} /></P>} />
                    <Route exact path="/circo" render={(p) => <P><ProgramsView {...p} /></P>} />
                    <Route exact path="/premio" render={(p) => <P><ProgramsView {...p} /></P>} />
                    <Route exact path="/collection" render={(p) => (<P><ProgramsView {...p} /></P>)} />
                    <Route exact path="/collection/performances" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    <Route exact path="/collection/musica" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    <Route exact path="/collection/arte-urbana" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    {/* menu noticias */}
                    <Route exact path="/arquivo-noticias" render={(p) => <P><NewsView {...p} /></P>} />
                    <Route exact path="/noticia/:slug" render={(p) => <P><NewsView {...p} /></P>} />
                    <Route exact path="/newsletter/subscrever" render={(p) => <P><NewsView {...p} /></P>} />
                    <Route exact path="/newsletter/ativar/:list/:token" render={(p) => <P><ActivateView {...p} /></P>} />
                    <Route exact path="/newsletter/educativo/" render={() => <P><SubscribeView /></P>} />
                    <Route exact path="/newsletter/remover/" render={() => <P><UnsubscribeView /></P>} />
                    {/* menu institucional */}
                    <Route exact path="/amigos" render={(p) => <P><InstitutionView {...p} /></P>} />
                    <Route exact path="/orgaos-sociais" render={(p) => <P><InstitutionView {...p} /></P>} />
                    <Route exact path="/balleteatro" render={(p) => <P><InstitutionView {...p} /></P>} />
                    <Route exact path="/estatutos" render={(p) => <P><InstitutionView {...p} /></P>} />
                    <Route exact path="/relatorios" render={(p) => <P><InstitutionView {...p} /></P>} />
                    {/* menu informacoes */}
                    <Route exact path="/bilheteira" render={(p) => <P><InformationView {...p} /></P>} />
                    <Route exact path="/contactos" render={(p) => <P><InformationView {...p} /></P>} />
                    <Route exact path="/como-chegar" render={(p) => <P><InformationView {...p} /></P>} />
                    <Route exact path="/normas-sala" render={(p) => <P><InformationView {...p} /></P>} />
                    <Route exact path="/area-tecnica" render={(p) => <P><InformationView {...p} /></P>} />
                    {/* outros */}
                    <Route exact path="/residencias" render={() => <P><ResidencyView /></P>} />
                    <Footer />
                </ThemeProvider>
            </Router>
        </ApolloProvider>
    )
}

export default App
